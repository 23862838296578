import React from 'react';
import './ripple-loader.scss';

export type RippleLoaderProps = {
  color?: 'primary' | 'secondary';
};

export default function RippleLoader({ color = 'primary' }: RippleLoaderProps) {
  const colorClassName =
    color === 'primary' ? 'ripple-color-primary' : 'ripple-color-secondary';
  return (
    <div className={`lds-ripple ${colorClassName}`}>
      <div></div>
      <div></div>
    </div>
  );
}
